import './Tab5.css';
import Information from '../Information/Information';
import romanovPhoto from '../../images/RD.jpg';
import rdvideo from '../../video/rdtab4.mp4';


function Tab5() {
    return (
        <section className='tab5'>
            <Information
                title='Химиолучевая терапия'
            >
                <p className='tab5__info-title'>Смотрим Романова Дениса Сергеевича:</p>
                <div className='tab5__block'>
                    <div className='tab5__container'>
                        <img className='tab5__img' src={romanovPhoto} alt='фотография Романова' />
                        <video className="tab5__video" src={rdvideo} controls />
                    </div>
                </div>
            </Information>
        </section>
    )
}

export default Tab5;