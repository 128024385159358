import './Tab3.css';
import Information from '../Information/Information';
import romanovPhoto from '../../images/RD.jpg';
import rdvideo from '../../video/rdtab3.mp4';

function Tab3() {
    return (
        <section className='tab3'>
            <Information
                title='Диагностика'
            >
                <p className='tab3__info-title'>Основные рекомендуемые обследования и анализы, в тч ИГХ:</p>
                <ul className='tab3__list'>
                    <li className='tab3__info'>1. Гистологическое исследование опухолевого материала (материала метастаз)</li>
                    <li className='tab3__info'>2. Иммуногистохимическое исследование опухолевого материала (включая статус HPV – вируса папилломы человека)</li>
                    <li className='tab3__info'>3. Анализы на онкомаркеры
                        SCC, РЭА, СА-19-9 (онкомаркеры не заменяют друг друга, однако среди них лишь SCC имеет непосредственное отношение к плоскоклеточному раку анального канала)
                    </li>
                    <li className='tab3__info'>4. Анализы крови ОАК и БАК</li>
                    <li className='tab3__info'>5. Общий анализ мочи (ОАМ)</li>
                    <li className='tab3__info'>6. Выполнение коагулограммы + (в контексте проведения химиотерапии капецитабином – ЭГДС для исключения эрозивного гастрита / язвы желудка или 12-перстной кишки)</li>
                    <li className='tab3__info'>7. Определение мутации в гене DPYD, определяющей повышенную чувствительность к 5-фторурацилу и его производным (как раз капецитабину)</li>
                    <li className='tab3__info'>8. Пальцевой осмотр анального канала</li>
                    <li className='tab3__info'>9. Пальпация лимфатических узлов (+ УЗИ с пункцией подозрительных, но если планируется ПЭТ-КТ, то до него пункцию лучше не делать)</li>
                    <li className='tab3__info'>10. Колоноскопия</li>
                    <li className='tab3__info'>11. МРТ малого таза с в/в контрастированием</li>
                    <li className='tab3__info'>12. ПЭТ – КТ с в/в контрастированием (оптимально) или комплекс КТ грудной клетки и органов брюшной полости с в/в контрастированием</li>
                    <li className='tab3__info'>13. Осмотр гинеколога для женщин</li>
                    <li className='tab3__info'>14. ПАП тест для женщин</li>
                    <li className='tab3__info'>15. В случае метастатического процесса PDL, MSI, мультигеномное секвенирование</li>
                </ul>
                <p className='tab3__block-title'>Смотрим Романова Дениса Сергеевича:</p>
                <div className='tab3__block'>
                    <div className='tab3__container'>
                        <img className='tab3__img' src={romanovPhoto} alt='фотография Романова' />
                        <video className="tab3__video" src={rdvideo} controls />
                    </div>
                </div>
                <div className='tab3__block'>
                    <p className='tab3__note'>
                        Не все вышеуказанные исследования и обследования проходят в рамках ОМС. Данный вопрос необходимо обсуждать с Вашим врачом.
                        Информация носит исключительно ознакомительно-справочный характер.
                    </p>
                </div>
            </Information>
        </section>
    )
}

export default Tab3;