import './Tab2.css';
import Information from '../Information/Information';
import rasulov from '../../images/RA.jpg';
import romanov from '../../images/RD.jpg';
import ravideo from '../../video/ratab2.mp4';
import rdvideo from '../../video/rdtab2.mp4';

function Tab2() {
    return (
        <section className='tab2'>
            <Information
                title='О диагнозе'
            >
                <p className='tab2__info-title'>Смотрим Расулова Арсена Османовича и Романова Дениса Сергеевича:</p>
                <div className='tab2__block'>
                    <div className='tab2__container'>
                        <img className='tab2__img' src={rasulov} alt='фотография Расулова' />
                        <video className="tab2__video" src={ravideo} controls />
                    </div>
                    <div className='tab2__container'>
                        <img className='tab2__img' src={romanov} alt='фотография Романова' />
                        <video className="tab2__video" src={rdvideo} controls />
                    </div>
                </div>
            </Information>
        </section>
    )
}

export default Tab2;