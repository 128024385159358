import './Header.css';
import icon from '../../images/tg.png';
import logo from '../../images/logo.png';
import icon18 from '../../images/icon18.svg';
import iconGif from '../../images/gif.gif'

function Header() {
  return (
    <header className='header'>
      <img
        className='header__icon18'
        alt='иконка 18+'
        src={icon18}
      />
      <div className='header__container'>
        <div className='header__block'>
          <div className='header__info'>
            <p className='header__info-text'>Сайт создан при поддержке сообщества пациентов</p>
            <div className='header__info-web'>
              <p className='header__info-name'>«Мы можем все»</p>
              <a
                className='header__link'
                href='https://t.me/+zpdVmwyIunhmOGMy'
                target='blanc'
              >
                <img
                  className='header__icon'
                  alt='иконка телеграм'
                  src={icon}
                />
              </a>
            </div>
          </div>
          <div className='header__info-link'>
            <a
              className='header__link-color'
              href='https://t.me/+zpdVmwyIunhmOGMy'
              target='blanc'>
              Подать заявку в пациентское сообщество можно по ссылке или по QR-коду
            </a>
            <img
              className='header__gif'
              alt='иконка телеграм'
              src={iconGif}
            />
          </div>
        </div>
        <img
          className='header__logo'
          alt='логотип'
          src={logo}
        />
      </div>
    </header>
  )
}

export default Header;
