import './Tab7.css';
import Information from '../Information/Information';
import rasulovVideo from '../../video/ratab7.mp4';
import rasulovVideo2 from '../../video/ra2tab7.mp4';
import rasulovPhoto from '../../images/RA.jpg';

function Tab7() {
    return (
        <section className='tab7'>
            <Information
                title='Наблюдение после хирургического лечения'
            >
                <p className='tab7__block-title'>Смотрим Расулова Арсена Османовича:</p>
                <div className='tab7__block'>
                    <div className='tab7__container'>
                        <img className='tab7__img' src={rasulovPhoto} alt='фотография Расулова' />
                        <video className="tab7__video" src={rasulovVideo} controls />
                    </div>
                    <div className='tab7__container'>
                        <img className='tab7__img' src={rasulovPhoto} alt='фотография Расулова' />
                        <video className="tab7__video" src={rasulovVideo2} controls />
                    </div>
                </div>
                <p className='tab7__info-title'>Основные рекомендуемые обследования и анализы после операции</p>
                <table className='tab7___table'>
                    <tbody>

                        <tr className='tab7__table-line'>
                            <th className='tab7__table-cellTitle'>Наименование</th>
                            <th className='tab7__table-cellTitle'>1-3 год</th>
                            <th className='tab7__table-cellTitle'>4-5 год</th>
                            <th className='tab7__table-cellTitle'>&#62; 5 лет</th>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>ОАК
                                <span className='tab7__tableCell-speq'>*</span>
                            </td>
                            <td className='tab7__tableCell'>Каждые 6 мес.</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>

                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>ОАМ
                                <span className='tab7__tableCell-speq'>*</span>
                            </td>
                            <td className='tab7__tableCell'>Каждые 6 мес.</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>

                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>БАК
                                <span className='tab7__tableCell-speq'>*</span>
                            </td>
                            <td className='tab7__tableCell'>Каждые 6 мес.</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>

                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>Онкомаркеры SCC</td>
                            <td className='tab7__tableCell'>Каждые 6 мес.</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>

                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>Консультация врача<br></br> онколога и осмотр</td>
                            <td className='tab7__tableCell'>Каждые 6 мес.
                                <span className='tab7__tableCell-speq'>**</span>
                            </td>
                            <td className='tab7__tableCell'>1 раз в год
                                <span className='tab7__tableCell-speq'>**</span>
                            </td>
                            <td className='tab7__tableCell'>1 раз в год
                                <span className='tab7__tableCell-speq'>**</span>
                            </td>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>Пальпация<br></br>лимфатических узлов<br></br>+УЗИ<br></br>лимфатических<br></br> узлов</td>
                            <td className='tab7__tableCell'>Каждые 6 мес.</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>УЗИ органов<br></br>брюшной полости</td>
                            <td className='tab7__tableCell'>Каждые 6 мес.</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>УЗИ органов<br></br>малого таза</td>
                            <td className='tab7__tableCell'>Каждые 6 мес.</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>МРТ малого таза<br></br>с в/в контрастированием</td>
                            <td className='tab7__tableCell tab7__tableCell_small'>по показаниям</td>
                            <td className='tab7__tableCell tab7__tableCell_small'>по показаниям</td>
                            <td className='tab7__tableCell tab7__tableCell_small'>по показаниям</td>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>Комплекс КТ<br></br>грудной клетки и<br></br>органов брюшной<br></br>полости с в/в<br></br>контрастированием<br></br>или ПЭТ КТ</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>Колоноскопия</td>
                            <td className='tab7__tableCell'>Каждые 2-3 года</td>
                            <td className='tab7__tableCell'>Каждые 2-3 года</td>
                            <td className='tab7__tableCell'>Каждые 2-3 года</td>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>Осмотр гинеколога для<br></br>женщин</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell tab7__tableCell_last'>ПАП тест для женщин</td>
                            <td className='tab7__tableCell tab7__tableCell_last'>1 раз в год</td>
                            <td className='tab7__tableCell tab7__tableCell_last'>1 раз в год</td>
                            <td className='tab7__tableCell tab7__tableCell_last'>1 раз в год</td>
                        </tr>
                    </tbody>
                </table>
                <p className='tab7__note'>*- именно онкологического смысла в сдаче этих анализов
                    по факту удаления от окончания лечения нет, смысл в контроле общего состояния организма</p>
                <p className='tab7__note'>У пациентов с высоким риском рецидива срок между обследованиями может быть сокращен
                </p>
                <p className='tab7__note'>** на основании пройденных исследований и обследований</p>
                <div className='tab7__block'>
                    <p className='tab7__block-note'>
                        Не все вышеуказанные исследования и обследования проходят в рамках ОМС данный вопрос необходимо обсуждать со своим врачом.
                        Информация носит рекомендательный характер.
                    </p>
                </div>
            </Information>
        </section>
    )
}

export default Tab7;