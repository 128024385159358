import './Tab4.css';
import Information from '../Information/Information';
import rasulovVideo from '../../video/ratab5.mp4';
import rasulovPhoto from '../../images/RA.jpg';

function Tab4() {
    return (
        <section className='tab4'>
            <Information
                title='Транспозиция яичников'
            >
                <p className='tab4__info-title'>Смотрим Расулова Арсена Османовича:</p>
                <div className='tab4__block'>
                    <div className='tab4__container'>
                        <img className='tab4__img' src={rasulovPhoto} alt='фотография Расулова' />
                        <video className="tab4__video" src={rasulovVideo} controls />
                    </div>
                </div>
                <div className='tab4__block tab4__block_tab4'>
                    <p className='tab4__info'>
                    Пациентам, как мужчинам, так и женщинам, желающим иметь детей необходимо рассмотреть вопрос о прохождении до химио-лучевой терапии процедуры по заморозке ооцитов (женские), сперматозоидов (мужские), либо эмбриона.
                    </p>
                </div>
            </Information>
        </section>
    )
}

export default Tab4;