import './Navigation.css';
import { Link,useLocation } from 'react-router-dom';

function Navigation () {
  const path = useLocation().pathname;

    return (
        <section className='navigation'>
          <ul className='navigation__list'>
            <li className="navigation__links">
              <Link to="/tab1" className={path === '/tab1' ? "navigation__link navigation__link_bold" : "navigation__link"}>Рекомендации</Link>
            </li>
            <li className="navigation__links">
              <Link to="/tab2" className={path === '/tab2' ? "navigation__link navigation__link_bold" : "navigation__link"} >О диагнозе</Link>
            </li>
            <li className="navigation__links">
              <Link to="/tab3" className={path === '/tab3' ? "navigation__link navigation__link_bold" : "navigation__link"} >Диагностика</Link>
            </li>
            <li className="navigation__links">
              <Link to="/tab4" className={path === '/tab4' ? "navigation__link navigation__link_bold" : "navigation__link"} >Транспозиция яичников</Link>
            </li>
            <li className="navigation__links">
              <Link to="/tab5" className={path === '/tab5' ? "navigation__link navigation__link_bold" : "navigation__link"} >ХЛТ</Link>
            </li>
            <li className="navigation__links">
              <Link to="/tab6" className={path === '/tab6' ? "navigation__link navigation__link_bold" : "navigation__link"} >После ХЛТ</Link>
            </li>
            <li className="navigation__links">
              <Link to="/tab7" className={path === '/tab7' ? "navigation__link navigation__link_bold" : "navigation__link"} >Хирургия</Link>
            </li>
            <li className="navigation__links">
              <Link to="/tab8" className={path === '/tab8' ? "navigation__link navigation__link_bold" : "navigation__link"} >Клиники и ФЦ</Link>
            </li>
          </ul>
        </section>
    )
}

export default Navigation;