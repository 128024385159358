import './Footer.css';

function Footer() {
    return (
        <footer className='footer'>
            <p className='footer__info'>«Мы можем все»</p>
        </footer>
    )
}

export default Footer;
